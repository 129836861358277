import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'

import Container from '../../../design-system/container/container';
import CoursePath from '../../CoursePath/CoursePath'

const Lessons = () => {
    const { lesson } = useParams();
    const [content, setContent] = useState("");

    useEffect(() => {
        const currentStepMD = `../course-v2/step--${lesson}.md`;
        // cookies.set('currentStep', step, { path: '/' });

        fetch(currentStepMD)
            .then((res) => res.text())
            .then((text) => setContent(text));
    }, [lesson]);

    return (
        <>
            <CoursePath />
            <Container>
                <ReactMarkdown children={content} />
            </Container>
        </>
    );
}

export default Lessons;
