// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { CourseLessonType, CourseContent, CourseLesson } = initSchema(schema);

export {
  CourseLessonType,
  CourseContent,
  CourseLesson
};