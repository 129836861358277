import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Link } from 'react-router-dom';

import Container from '../../../design-system/container/container';
import CoursePath from '../../CoursePath/CoursePath';

import { CourseLessonType } from '../../../models';

// const posts = await DataStore.query(Post);
// console.log(posts);
const Homepage = () => {
  const [course, setCourse] = useState({});

  useEffect(() => {
    fetchCourse();
  }, []);

  async function fetchCourse() {
    const courseReponse = await DataStore.query(CourseLessonType);
    setCourse(courseReponse);
  }

  console.log(course);
  return (
    <>
      <CoursePath />
      <Container>
        <h1>Welcome</h1>

        <p>Hi there!</p>

        <p>I'm thrilled you're interested in learning how to code your own website.<br />
        This is a great opportunity to expand your skills and create something unique and functional.</p>

        <p style={{ marginBottom: '32px' }}>To access the course navigation, simply click the small arrow icon on the left side to reveal the table of contents.</p>

        <Link className='button button-primary' to="/lessons/1">Let's get started!!</Link>
      </Container>
    </>
  );
}

export default Homepage;
