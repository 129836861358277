import React, { useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

// import Header from './Components/Header/';

import Homepage from './Components/Pages/Homepage/Homepage';
import Lessons from './Components/Pages/Lessons/Lessons';

const client = new ApolloClient();

const App = () => {
  const mobile = window.matchMedia('(max-width: 768px)').matches;

  const MobileNotice = () => {
    return (
      <>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh"
        }}>This app doesn't work on mobile</div>
      </>
    )
  }
  
  return (
      <ApolloProvider client={client}>
        {mobile ? 
          <MobileNotice />
        : <div className={`App`}>
          {/* <Header></Header> */}
          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<Homepage />} />
              <Route path='/lessons/:lesson' element={<Lessons />} />
            </Routes>
          </BrowserRouter>
        </div>}
      </ApolloProvider>
  );
}

export default App;
