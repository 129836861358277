import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './CoursePath.css'

const Path = () => {
    const [open, setOpen] = useState(false);

    return (

        <div className={`course-path${open ? ' open':''}`} onClick={() => setOpen(!open)}>
            <div className='course-path-container'>
                <section>
                    <div className='chapter'>Welcome</div>
                    <ul>
                        <li><Link to='/'>Welcome</Link></li>
                    </ul>
                </section>
                <section>
                    <div className='chapter'>Beginner's Guide to HTML</div>
                    <ul>
                        <li><Link to='/lessons/1'>Introduction to HTML</Link></li>
                        <li><Link to='/lessons/2'>HTML Document Structure</Link></li>
                        <li><Link to='/lessons/3'>Text Formatting</Link></li>
                        <li><Link to='/lessons/4'>Lists</Link></li>
                        <li><Link to='/lessons/5'>Links and URLs</Link></li>
                        <li><Link to='/lessons/6'>Images</Link></li>
                        <li><Link to='/lessons/7'>Forms</Link></li>
                        <li><Link to='/lessons/8'>Semantic HTML</Link></li>
                        <li><Link to='/lessons/9'>HTML Comments</Link></li>
                        <li><Link to='/lessons/10'>Basic CSS Styling</Link></li>
                        <li><Link to='/lessons/11'>Troubleshooting</Link></li>
                    </ul>
                </section>
                <section>
                    <div className='chapter'>CSS Basics: A Comprehensive Guide</div>
                    <ul>
                        <li><Link to='/lessons/12'>Introduction to CSS</Link></li>
                        <li><Link to='/lessons/13'>Setting Up CSS</Link></li>
                        <li><Link to='/lessons/14'>CSS Selectors and Properties</Link></li>
                        <li><Link to='/lessons/15'>CSS Box Model</Link></li>
                        <li><Link to='/lessons/16'>Text and Fonts</Link></li>
                        <li><Link to='/lessons/17'>Styling Links</Link></li>
                        <li><Link to='/lessons/18'>CSS Display and Positioning</Link></li>
                        <li><Link to='/lessons/19'>Margins, Padding, and Borders</Link></li>
                        <li><Link to='/lessons/20'>Backgrounds and Colors</Link></li>
                        <li><Link to='/lessons/21'>CSS Classes and IDs</Link></li>
                        <li><Link to='/lessons/22'>Floats and Clearing</Link></li>
                        <li><Link to='/lessons/23'>Flexbox Layout</Link></li>
                        <li><Link to='/lessons/24'>CSS Grid Layout</Link></li>
                        <li><Link to='/lessons/25'>Pseudo-classes and Pseudo-elements</Link></li>
                        <li><Link to='/lessons/26'>Responsive Design with Media Queries</Link></li>
                        <li><Link to='/lessons/27'>CSS Transitions and Animations</Link></li>
                        <li><Link to='/lessons/28'>CSS Units and Values</Link></li>
                        <li><Link to='/lessons/29'>CSS Specificity and Inheritance</Link></li>
                        <li><Link to='/lessons/30'>Debugging and Troubleshooting CSS</Link></li>
                    </ul>
                </section>
                <section>
                    <div className='chapter'>Design Systems: A Practical Guide</div>
                    <ul>
                        <li><Link to='/lessons/31'>Unlocking the Power of Design Systems</Link></li>
                        <li><Link to='/lessons/32'>Set up the basics</Link></li>
                        <li><Link to='/lessons/33'>Colors</Link></li>
                        <li><Link to='/lessons/34'>Typography</Link></li>
                        <li><Link to='/lessons/35'>Buttons</Link></li>
                        <li><Link to='/lessons/36'>Spacing and Layout</Link></li>
                    </ul>
                </section>
                <section>
                    <div className='chapter'>Building Your Website: Essential Blocks in Your Toolkit</div>
                    <ul>
                        <li><Link to='/lessons/37'>Set up the basics... again</Link></li>
                        <li><Link to='/lessons/38'>Hero Section</Link></li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default Path;
